























































import {Component, Mixins} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Swiper from 'swiper'
import {SupportedTokenCollection} from '@/model/collection/SupportedTokenCollection'

@Component
export default class GasbotSupportedTokens extends Mixins(MixinScreenSize) {
  isBeginning = true
  isEnd = false

  swiper!: Swiper

  collection = new SupportedTokenCollection()

  get swiperOption() {
    return {
      slidesPerView: 3.6,
      spaceBetween: 0,
      centeredSlides: true,
      centeredSlidesBounds: true,
      breakpoints: {
        480: {
          slidesPerView: 4,
          centeredSlides: false,
          centeredSlidesBounds: false,
        },
        640: {
          slidesPerView: 5,
        },
      },
      autoplay: {
        delay: 3000,
      },
    }
  }

  async created() {
    this.applyCollection()
    await this.populateResources()
  }

  applyCollection() {
    this.collection.perPage = null
    this.collection.orderBy = 'order'
    this.collection.asc = true
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [this.collection.expand()]

    await this.$await.run('listSupportedToken', () => Promise.all(promises))
  }

  async initSwiper(swiper: Swiper) {
    this.swiper = swiper
    this.updateEvent()
  }

  updateEvent() {
    this.isBeginning = this.swiper.isBeginning
    this.isEnd = this.swiper.isEnd
  }

  prevSlide() {
    if (!this.swiper.isBeginning) {
      this.swiper.slidePrev()
    }
    this.updateEvent()
  }

  nextSlide() {
    if (!this.swiper.isEnd) {
      this.swiper.slideNext()
    }
    this.updateEvent()
  }
}
