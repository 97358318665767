





























import AppFooter from '@/components/AppFooter.vue'
import NavbarMenu from '@/components/NavbarMenu.vue'
import GasbotAbout from '@/components/gasbot/GasbotAbout.vue'
import GasbotCommands from '@/components/gasbot/GasbotCommands.vue'
import GasbotFaq from '@/components/gasbot/GasbotFaq.vue'
import GasbotHeader from '@/components/gasbot/GasbotHeader.vue'
import GasbotImageHeader from '@/components/gasbot/GasbotImageHeader.vue'
import GasbotSupportedServers from '@/components/gasbot/GasbotSupportedServers.vue'
import GasbotSupportedTokens from '@/components/gasbot/GasbotSupportedTokens.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {MetaInfo} from 'vue-meta'
import {Component, Mixins} from 'vue-property-decorator'

@Component({
  components: {
    GasbotSupportedServers,
    GasbotImageHeader,
    GasbotCommands,
    GasbotAbout,
    GasbotFaq,
    GasbotHeader,
    GasbotSupportedTokens,
    AppFooter,
    NavbarMenu,
  },
})
export default class GasBotView extends Mixins(MixinScreenSize) {
  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.gasbot'),
    }
  }

  mounted() {
    window.scrollTo(0, 0)
  }
}
